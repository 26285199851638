import React from "react"
import classes from "./Grid.module.scss"

export default ({ className, gridItems, columns }) => {
  let gridColClass
  switch (columns) {
    case 1:
      gridColClass = classes.GridCol1
      break
    case 2:
      gridColClass = classes.GridCol2
      break
    case 3:
      gridColClass = classes.GridCol3
      break
    case 4:
      gridColClass = classes.GridCol4
      break
    case 5:
      gridColClass = classes.GridCol5
      break
    case 6:
      gridColClass = classes.GridCol6
      break
    default:
      gridColClass = classes.GridCol1
  }
  const classNames = [className, classes.Grid, gridColClass]

  return (
    <div className={classNames.join(" ")}>
      {gridItems.map(item => (
        <div className={classes.GridItem} key={item.key}>
          {item}
        </div>
      ))}
    </div>
  )
}
