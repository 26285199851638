import React from "react"
import classes from "./Tile.module.scss"
import Img from "gatsby-image"

export default ({ className, image, title }) => {
  const classNames = [className, classes.Tile]

  return (
    <div className={classNames.join(" ")}>
      <Img
        className={classes.TileImage}
        fluid={image}
        alt={title}
        style={{ width: "100%", height: "auto" }}
      />
      <h2 className={classes.TileTitle}>{title}</h2>
    </div>
  )
}
